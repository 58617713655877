import { destroy, get, post, put } from "./Api";
import DataSheetEntitie from "../../models/DataSheetEntitie";
import SubfamilyDataSheetModel from "../../models/SubfamilyDataSheetModel";



const DataSheetService = {    
    getDataSheetById: async (id: string, language: string) => 
                get<{url: string}>(`datasheet/datasheetbyid/${id}/${language}`),     // Passar o token noo header Authorization 
    
    getDataSheetsByFamily: async (id: string, language: string) => 
            get<DataSheetEntitie[]>(`datasheet/datasheetfamily/${id}/${language}`),

    getSubfamilyDataSheet: async (id: string, language : string) => 
        get<SubfamilyDataSheetModel>(`datasheet/subfamilydatasheet/${id}/${language}`)
};

export default DataSheetService;

