import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import SignInCard from './SignInCard';

interface ForgotPasswordProps {
  open: boolean;
  handleClose: () => void;
  returnUrl?: string;
}

export default function LoginCardDialog({ open, handleClose, returnUrl }: ForgotPasswordProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      slotProps={{
        paper: {
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleClose();
          },
          sx: { backgroundImage: 'none' },
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'left', color: 'gray'}}>Authentication</DialogTitle>
      <DialogContent
        sx={{ display: '', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Sign in to see more details. Access level need authentication!
        </DialogContentText>
        <SignInCard handleClose={handleClose} returnUrl={returnUrl}/>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button variant="outlined" onClick={handleClose}>Cancel</Button>        
      </DialogActions>
    </Dialog>
  );
}