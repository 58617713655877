import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword';
import style from './RequestAccessCard.module.css';
import { GoogleIcon, FacebookIcon, MafirolIcon } from './CustomIcons';
import { Link, useHistory } from 'react-router-dom';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import Util from '../../utils/Util';
import i18n from '../../i18n';
import { AuthContext } from '../../context/AuthContext';
import AuthService from '../../services/api/Auth';

interface LoginModel {
  email: string;
  password: string;
}
interface Props {
  handleClose?: () => void;
  returnUrl?: string;
}

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
//   ...theme.applyStyles('dark', {
//     boxShadow:
//       'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
//   }),
}));

export default function SignInCard(props: Props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { login } = React.useContext(AuthContext);
  const [serverErrors, setServerErrors] = React.useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const language = Util.splitLanguage(i18n.language, 0);
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setServerErrors([]); // Limpa os erros anteriores
    setButtonDisabled(true);

    if (emailError || passwordError) {
      event.preventDefault();
      return;
    }
    const formData = new FormData(event.currentTarget);

    const loginData: LoginModel = {
      email: String(formData.get('email')),
      password: String(formData.get('password')),
    };
    // console.log(datas);    
    
    formData.append("email", loginData.email);
    formData.append("password", loginData.password);
    
    if(loginData)
    {
      await AuthService.signin(formData).then((result) => {
        const data = result.data;
        const statusOK = result.status;
        
        // console.log('Status code de login foi', statusOK);
        // props.handleClose(); // fechar modal caso aberta e login
        handleClose();
       
        if(statusOK === 200){
          const { accessToken } =  data;
          // if(accessToken && props.returnUrl){
          //   auth?.login(accessToken, props.returnUrl);            
          // }
          if(accessToken){
            // console.log('returnurl in SignInCard', props.returnUrl)
            login(accessToken, props.returnUrl, props.handleClose);                       
          }

          
          // console.log('data', data);
          // console.log('token', accessToken);
        }        
          else alert("Login falhou! m pst")      
              
      }).catch((error: any) => { 
        
        // console.log('error', error);
        // console.log('error.response', error.response);
        setButtonDisabled(false);

        if (error.response) {
          // Se o backend retornou erros de validação, capturamos aqui
          if (Array.isArray(error.response.data)) {
              setServerErrors(error.response.data.map((err: any) => err.description || err.errorMessage || err)); 
          } else {
              setServerErrors([error.response.data || "Erro desconhecido"]);
          }
        } else {
            setServerErrors(["Erro ao conectar com o servidor"]);
        }
      });
    }


  };

  const validateInputs = () => {
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  return (
    <Card variant="outlined">      
      <MafirolIcon />
      <Typography
        component="h4"
        variant="body2"
        sx={{ width: '100%', fontSize: '16px', textAlign: 'center' }}
      >
        Welcome to the customer area!
      </Typography>
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            error={emailError}
            helperText={emailErrorMessage}
            id="email"
            type="email"
            name="email"
            placeholder="your@email.com"
            autoComplete="email"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <a              
              className={style.Link}       
              type="button"
              onClick={handleClickOpen}
            >
              Forgot your password?
            </a>
          </Box>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            // autoComplete="current-password"
            // autoFocus
            required
            fullWidth
            variant="outlined"
            color={passwordError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <ForgotPassword open={open} handleClose={() => handleClose()} />
        <Button type="submit" fullWidth variant="contained" onClick={validateInputs} disabled={buttonDisabled}>
          Sign in
        </Button>        
        <Typography sx={{ textAlign: 'center' }}>
          Don&apos;t have an account?{' '}
          <span>              
            <Link to={`/${language}/request-access`}
              className={style.Link}              
            >
              Request Access
            </Link>
          </span>
        </Typography>
      </Box>
       {serverErrors.length > 0 && (
          <div style={{ color: "red", marginTop: "10px" }}>
              <ul>
                  {serverErrors.map((error, index) => (
                      <li key={index}>{error}</li>
                  ))}
              </ul>
          </div>
      )}
      {/* <Divider>or</Divider>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => alert('Sign in with Google')}
          startIcon={<GoogleIcon />}
          disabled
        >
          Sign in with Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => alert('Sign in with Facebook')}
          startIcon={<FacebookIcon />}
          disabled
        >
          Sign in with Facebook
        </Button>
      </Box> */}
    </Card>
  );
}