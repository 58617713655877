import styles from './Switch.module.css';

interface SwitchProps {
  checked: boolean;
  onChange: () => void;
}

export function Switch({ checked, onChange }: SwitchProps) {
  return (
    <button
      type="button"
      className={`${styles.switch} ${checked ? styles.checked : styles.unchecked}`}
      role="switch"
      aria-checked={checked}
      onClick={onChange}
    >
      <span className={`${styles.handle} ${checked ? styles.handleChecked : styles.handleUnchecked}`} />
    </button>
  );
}
