import { Container, Row, Col } from "react-bootstrap";

import { FamilyModel } from "../../models/FamilyModel";
import { TypeModel } from "../../models/TypeModel";

import MainTitle from "../../components/MainTitle/MainTitle";
import FamilyDescription from "../../components/FamilyDescription/FamilyDescription";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import CategoryItem from "../../components/CategoryItem/CategoryItem";

import "./Family.css";
import { useCallback, useContext, useEffect, useState } from "react";
import MediaViewer from "../MediaViewer/MediaViewer";
import DataSheetService from "../../services/api/DataSheetService";
import DataSheetEntitie from "../../models/DataSheetEntitie";
import Util from "../../utils/Util";
import i18n from "../../i18n";
import { MdArrowBack } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import VetorBack from "../../assets/images/VetorBack.png";
import { Github, Linkedin, Mail, ExternalLink, ChevronDown, Menu, X, Lock, Code2, Database, Server, Globe, ArrowRightFromLine, Plus, LockKeyhole, LockKeyholeOpen } from 'lucide-react';
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import { Box, Button, Typography } from "@mui/material";
import LoginCardDialog from "../Signin e RequestAccess/LoginCardDialog";
import { AuthContext } from "../../context/AuthContext";




interface Props {
  family?: FamilyModel;
  downloadDocument: (item: TypeModel) => void;
  askInformation: () => void;
  className?: string;
}



const Family = (props: Props) => {
  const params = useParams(); 
  const { gamma, line } = params;
  const history = useHistory();

  const [currentImage, setCurrentImage] = useState(0);
  const [visible, setVisible] = useState(false);
  const language = Util.splitLanguage(i18n.language, 0);
  // const[dataSheets, setDataSheets] = useState<DataSheetEntitie[]>([]);
  const [familyId, setFamilyId] = useState<any>();


  const { isAuthenticated }   = useContext(AuthContext);
  // const [ authenticated, setAuthenticated ]   = useState<boolean>(true);
  const [ authenticated, setAuthenticated ]   = useState<boolean>(false);

  useEffect(() => { 
    setAuthenticated(isAuthenticated);
  },[isAuthenticated]);


  const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };



  const openImageViewer = useCallback((index) => {
    setVisible(true);
    setCurrentImage(index);
  }, []);

  const closeImageViewer = () => {
    setVisible(false);
    setCurrentImage(0);
  };

  var images: { src: string }[] = [];

    useEffect(() => {

      setFamilyId(props.family?.id)
    },)

    // useEffect(() =>{
    //   if(props.family?.id) {
    //     DataSheetService.getDataSheetsByFamily(familyId, language.toUpperCase()).then((res) =>{
    //       setDataSheets(res.data);
    //     })
    //   }
    //   },[familyId])

    const backLevelPage = () => {
      history.push(`/${language}/${gamma}/${line}`);
  }


  return (
    <Container fluid className={`family ${props.className}`}>
      <Row>
        <Col md={12} className="pl-0">
          <Row>
            <Col md={8}>
              <MainTitle media={props.family?.media} height="50vh" />
            </Col>
            <Col
              md={4}
            // className="position-relative ml-5"
            >
              <FamilyDescription
                articleClassName="mt-4"
                family={props.family}
                askInformationColSpec={{ lg: 12, xl: 6 }}
                documentsColSpec={{ lg: 12, xl: 6 }}
                downloadDocument={props.downloadDocument}
                askInformation={props.askInformation}

                // dataSheets={dataSheets}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="family-breadcrumb">
              <BreadcrumbPath
                ancestors={props.family?.ancestors}
                current={props.family?.name}
              />
               <div
                className="backLevelPageFamily"
                style={{ cursor: "pointer" , textAlign: "left", marginTop: '-50px', marginBottom: "3rem", position: "absolute"}} 
                onClick={() => backLevelPage()}    
            >
                <> 
                    <img src={VetorBack} alt=""  width={30}/>
                </>
            </div>    
            </Col>
            
          </Row>
          <article>
                  
            <Row className="family-subfamilies m-auto">
              {props.family?.subfamilies.map((item, index) => {
                images.push({ src: item.primaryMedia.path });
                return (
                  <Col
                  sm={4}
                    md={4}
                    lg={window.innerWidth >= 1300 ? 3 : 4}
                    className="family-subfamilies-col "
                    key={index}
                  >
                    {authenticated ? (
                      <>
                      <CategoryItem
                        primaryMedia={item.primaryMedia}
                        secondaryMedia={item.secondaryMedia}
                        name={item.name}
                        energyEfficiencyMedia={item.energyEfficiencyMedia}
                        style={{
                          nameClassName: "text-center text-uppercase",
                          scaleImage: true,
                          background: true,
                        }}
                        key={index}
                        path={`${props.family?.path}/${item.id}`}
                        // imageViewer={() => openImageViewer(index)}
                        />
                        <Box sx={{textAlign: 'left',  alignItems: 'center'}}>
                          <Link
                            className="Link"
                            to={`${props.family?.path}/${item.id}`} 
                            style={{display: 'flex', alignItems: 'center !important', color: '#6495edfc', padding: "6px 0"}}
                            >
                            <LockKeyholeOpen width={18}/>  &nbsp;   
                            <Typography display={'flex'} alignItems={'center'} variant="body2" mt={0.6}>Ver Produto</Typography>
                          </Link>
                        </Box>
                        </>
                    ): (
                      <>
                        <CategoryItem
                          primaryMedia={item.primaryMedia}
                          secondaryMedia={item.secondaryMedia}
                          name={item.name}
                          energyEfficiencyMedia={item.energyEfficiencyMedia}
                          style={{
                            nameClassName: "text-center text-uppercase",
                            scaleImage: true,
                            background: true,
                          }}
                          key={index}                      
                          imageViewer={() => openImageViewer(index)}
                        />
                        <Box sx={{textAlign: 'left', alignItems: 'center'}}>
                          <Button
                            className="Link"
                            onClick={handleClickOpen}
                            // to={`/${language}/signin`} 
                            style={{display: 'flex', alignItems: 'center !important', color: '#6495edfc', padding: "6px 0"}}
                            
                            >
                              <LockKeyhole width={18} /> &nbsp;    
                              <Typography display={'flex'} alignItems={'center'} variant="body2" mt={0.6}>Ver Produto</Typography>
                          </Button>
                          {/* <Typography display={'block'} variant="body2" color={"gray"} fontSize={12}>* (Requer Autenticação)</Typography> */}
                        </Box>
                      </>
                    )}
                  </Col>
                );
              })}
            </Row>
          </article>
        </Col>

        {/* <Col md={3} className="position-relative ml-5">
          <FamilyDescription
            articleClassName="mt-5 pt-5"
            family={props.family}
            askInformationColSpec={{ lg: 12, xl: 6 }}
            documentsColSpec={{ lg: 12, xl: 6 }}
            downloadDocument={props.downloadDocument}
            askInformation={props.askInformation}

            // dataSheets={dataSheets}
          />
        </Col> */}

      </Row>
      <MediaViewer
        visible={visible}
        onClose={closeImageViewer}
        activeIndex={currentImage}
        images={images}
      />
      <LoginCardDialog open={open} handleClose={handleClose} returnUrl={window.location.pathname}/>
    </Container>
  );
};

export default Family;
