import { AuthResponse } from "../../interfaces/AuthResponse";
import RequestAccess from "../../interfaces/RequestAccess";
import UserDataModel from "../../interfaces/User/UserData";
import { ResetPasswordFormData } from "../types/reset-password";
import ResetTokenRequest from "../types/ResetTokenRequest";
import { destroy, get, post, put } from "./Api";

const endpoint = "/auth";

const AuthService = {
    signin: async(modelData: FormData) => 
        post<AuthResponse>(`${endpoint}/login`, modelData, { headers: { 'Content-Type': 'multipart/form-data' } }),
    
    requestAccess: async(modelData: FormData) => 
        post(`${endpoint}/requestaccess`,  modelData, { headers: { 'Content-Type': 'multipart/form-data' } }),

    getUserById: async(userId: string) => 
        get<UserDataModel>(`${endpoint}/getuserbyid/${userId}`),
    
    getGetAllUsers: async() => 
        get<UserDataModel[]>(`${endpoint}/getallusers`),
    
    changeUserStatus: async(userId: string, activeStatus: boolean) => 
        put<UserDataModel[]>(`${endpoint}/changeuserstatus/${userId}/${activeStatus}`),
    
    removeUser: async(userId: string) => 
        destroy<UserDataModel[]>(`${endpoint}/deleteuser/${userId}`),
    
    forgotPassword: async(userMail: string) => 
        post(`${endpoint}/forgotpassword/${userMail}`),
    
    validateResetToken: async(resetTokenData: ResetTokenRequest) => 
        post(`${endpoint}/validateresettoken/`, resetTokenData ),        
   
    resetPassword: async(resetPasswordRequestData: ResetPasswordFormData) => 
        post(`${endpoint}/resetpasswordrequest/`, resetPasswordRequestData ),
   
};

export default AuthService;