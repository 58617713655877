import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { AuthContext } from '../../context/AuthContext';
import UserDataModel from '../../interfaces/User/UserData';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user, logout } = React.useContext(AuthContext);
  const [userData, setUserData] = React.useState<UserDataModel>();
  const [userName, setUserName] = React.useState<string>("M");
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  React.useEffect(() => {
    if(userData){
      getUserName(userData.fullName);      
    }
  },[userData]);

  React.useEffect(() => {
    if(user) { 
      setUserData(user);      
    }    
  },[user]);

  

  const getUserName = ( userName : string ) => {
    if(userName){
      let stringArrayLength = userName.split(' ');
      // console.log('stringLength', stringArrayLength);

      if(stringArrayLength.length > 1 ) {
        // console.log(stringArrayLength[0], stringArrayLength[1]);        
        // console.log(stringArrayLength[0].substring(0,1).toUpperCase(), stringArrayLength[1].substring(0,1).toUpperCase());        
        setUserName(`${stringArrayLength[0].substring(0,1).toUpperCase()}${stringArrayLength[1].substring(0,1).toUpperCase()}`);
      }
      else if(stringArrayLength.length > 0) {
        // console.log(stringArrayLength[0].substring(0,2).toUpperCase());      
        setUserName(`${stringArrayLength[0].substring(0,2).toUpperCase()}`)  ;
      }
    }
  };


  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{userData ? userName : "MF"}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem  onClick={handleClose}>
          <Avatar /> {userData?.fullName ?? ""}
        </MenuItem>
        <MenuItem>
          <BusinessIcon sx={{ color: "#bdbdbd", marginRight: 1 }} /> {userData?.companyName ?? ""}
        </MenuItem>
        <MenuItem>
          <EmailIcon sx={{ color: "#bdbdbd", marginRight: 1 }} /> {userData?.email ?? ""}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Complete your account
        </MenuItem>
        <MenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
