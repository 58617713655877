import * as React from 'react';
import './Dashboard.module.css';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Layout from './components/Layout';
import Header from './components/Header';
import { CssVarsProvider } from '@mui/joy/styles';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import Navigation from './components/Navigation';
import  Users from '../../components/Users/Users';
// import  Users from '../../components/Users/Users';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';
import Util from '../../utils/Util';
import { Link } from 'react-router-dom';


export interface User {
  id: number;
  name: string;
  email: string;
  active: boolean;
  avatar: string;
}


export default function Dashboard() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);
  
  return (
    <CssVarsProvider disableTransitionOnChange>
      {/* <CssBaseline /> */}
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <Navigation />
        </Layout.SideDrawer>
      )}
      <Stack
        id="tab-bar"
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'space-around',
          display: { xs: 'flex', sm: 'none' },
          zIndex: '999',
          bottom: 0,
          position: 'fixed',
          width: '100dvw',
          py: 2,
          backgroundColor: 'background.body',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Link to={`/${language}`}>
          <Button
            variant="plain"
            color="neutral"
            component="a"
            size="sm"
            startDecorator={<HomeIcon />}
            sx={{ flexDirection: 'column', '--Button-gap': 0 }}
          >
            Mafirol
          </Button>
        </Link>      
        <Link to={`/${language}/admin/dashboard`}>
          <Button
            variant="plain"
            color="neutral"
            aria-pressed="true"
            component="a"          
            size="sm"
            startDecorator={<PeopleAltRoundedIcon />}
            sx={{ flexDirection: 'column', '--Button-gap': 0 }}
            >
            Users
          </Button>
        </Link>
        {/* <Button
          variant="plain"
          color="neutral"
          component="a"
          href="/joy-ui/getting-started/templates/files/"
          size="sm"
          startDecorator={<FolderRoundedIcon />}
          sx={{ flexDirection: 'column', '--Button-gap': 0 }}
        >
          Files
        </Button> */}
      </Stack>
      <Layout.Root
        sx={[
          drawerOpen && {
            height: '100vh',
            overflow: 'hidden',
          },
        ]}
      >
        <Layout.Header>
          <Header />
        </Layout.Header>
        <Layout.SideNav>
          <Navigation />
        </Layout.SideNav>       
        <Layout.Main >            
            <Users  title="Utilizadores do Site" />
            {/* <UserManagement /> */}
            {/* <UserList /> */}
        </Layout.Main>
      </Layout.Root>
    </CssVarsProvider>
  );
}