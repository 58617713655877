import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, Redirect, Route } from "react-router-dom";
import Util from "../utils/Util";
import i18n from "../i18n";
import JwtModel from "../models/JwtModel";
import { jwtDecode } from "jwt-decode";
import { hasRole } from "../utils/AuthUtils";



const ProtectedRoute = ({ element, rolesRequireds, exact, path, key}: { element: JSX.Element; rolesRequireds?: string[] | string, exact?: boolean, path?: string, key: number}) => {
    const auth = useContext(AuthContext);
    const language = Util.splitLanguage(i18n.language, 0);
    const [token] = useState<string | null>(localStorage.getItem("token"));
    console.warn("Token em protected route é: ", token);
                    
    if(!auth?.token) return <Redirect to={`/${language}/signin`} />
    if(token) {
       try {        
            console.warn("RolesRequireds em protected route é: ", rolesRequireds);
           
           if(rolesRequireds && !hasRole("Admin")){
                // const decodedToken: JwtModel = jwtDecode(token);
                // console.warn("DecodedToken em protected route é: ", decodedToken);

                // if(!decodedToken.role) {                     
                //     return <Redirect to="/" />
                // }

                // const userRoles  = Array.isArray(decodedToken?.role) ? decodedToken?.role : [decodedToken?.role];

                // if(!userRoles) { 
                //     console.warn("Acesso negado: usuário sem permissão.");
                //     return <Redirect to="/" />
                // }
                
                // const requiredRolesArray = Array.isArray(rolesRequireds) ? rolesRequireds : [rolesRequireds];
                // const hasRequiredRole = requiredRolesArray.some(role => userRoles.includes(role));

                // if(!hasRequiredRole){
                //     console.warn("Acesso negado: usuário sem permissão necessária.");
                //     return <Redirect to="/" />
                // }
                    return <Redirect to="/" />
            }
        } catch (error){
            console.error("Erro ao decodificar o token:", error);
            return <Redirect to={`/${language}/signin`} />;
        }
    }

    return <Route key={key} exact={exact} path={path} component={element}/>;
};

export default ProtectedRoute;