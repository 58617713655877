import ContactSpecificForm from "../../components/ContactSpecificForm/ContactSpecificForm";
import MediaViewer from "../../components/MediaViewer/MediaViewer";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";

import SubfamilyDataSheetModel from "../../models/SubfamilyDataSheetModel";
import DataSheetFeatureWithTranslation from "../../models/DataSheetFeatureWithTranslation";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";

import DataSheetService from "../../services/api/DataSheetService";
import Gamma from "../../services/api/Gamma";
import Category from "../../services/api/Category";
import Util from "../../utils/Util";

import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NameSpace , contactForm } from "../../data/AppLanguage";
import { useTranslation } from "react-i18next";
import { MdArrowBack, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import style from './SubfamilyDataSheet.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import "./SubfamilyDataSheet.css";
import TabSubFamilyDataSheetsMobile from "./TabSubFamilyDataSheetsMobile/TabSubFamilyDataSheetsMobile";
import OpenPdfModalDialog from "../../components/Signin e RequestAccess/OpenPdfModalDialog";



const SubfamilyMobile = () => {
    const params = useParams<any>();    
    const { gamma, line, family, subfamily } = params;
    const dataSheetId = subfamily;
    const history = useHistory();
    
    const [gammaName, setGammaName] = useState<string>("");
    const [lineName, setLineName] = useState<string>("");
    const [familyName, setFamilyName] = useState<string>("");
    const [urlToPdfGenerated, setUrlToPdfGenerated] = useState<string>("");

    const [subfamilyDataSheet, setSubfamilyDataSheet] = useState<SubfamilyDataSheetModel>();    
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [imageSelected, setImageSelected] = useState<string>(subfamilyDataSheet?.subfamilyMediaUrl ?? "");
    const [selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);                
    const [hasTable, setHasTable] = useState<boolean>(false);
    const [listImages, setListImages] = useState<{ src: string }[]>([]);    
    const [isLoading, setIsLoading] = useState<boolean>(false);    

    const { t, i18n } = useTranslation([ NameSpace.ROUTES ]);
    const language = Util.splitLanguage(i18n.language, 0);
    const contactFormRef = useRef<HTMLElement>(null);    
    const Empty = "https://mm.mafirol.info/empty.png";

    const [visible, setVisible] = useState(false);
    const [ activeImageIndex, setActiveImageIndex] = useState(0);
      const [countdown, setCountdown] = useState(0);
       const [hover, setHover] = useState(false);
      
    
    var images: { src: string }[] = [];

    // console.log(params);

    const askInformation = () => {
        if (contactFormRef.current) {
          contactFormRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
    };
  
     const [open, setOpen] = useState(false);
          
            const handleClickOpen = () => { // USAR NO BOTÃO QUE EXISTIRÁ APÓS O PDF SER GERADO, ABRIR AUTOMATICAMENTE QD O PDF GERADO
              setOpen(true);
            };
          
            const handleClose = () => {
              setOpen(false);
            };

    useEffect(() => {
        Gamma.getGammas(language).then((res) => {
            var result = res.data;
            const gammaNameResult = result.find(item => item.path === gamma)?.name;
            setGammaName(gammaNameResult ?? "");
        })

        if(gammaName !== ""){
            Category.getLineFamilies(gamma, line, language).then((res) => {
                // console.log('line e family', res.data)
                var result = res.data;
                setLineName(result.current.name);
                const familyNameResult = result.successors.find(item => item.path === family)?.name;
                setFamilyName(familyNameResult ?? "");
            })
        }
        

        setIsSmallScreen(window.innerWidth < 954); // Verifique se a largura da tela é menor que 1200px ao carregar a página
        window.addEventListener("resize", () => {
            setIsSmallScreen(window.innerWidth < 954); // Atualize o estado quando a tela for redimensionada
        });

        return () => {
            window.removeEventListener("resize", () => {});
        };
    
    }, [gammaName]);

   
    const openImageViewer = () => {
        setVisible(true);
    };

    const closeImageViewer = () => {
       setVisible(false);
    }


    useEffect(() => {
        if(dataSheetId){
            DataSheetService.getSubfamilyDataSheet(dataSheetId, language.toLocaleUpperCase()).then((res) => {                
                const result  = res.data;
                setSubfamilyDataSheet(res.data);
                
                if(result.subfamilyMediaUrl){
                    setImageSelected(result.subfamilyMediaUrl);
                    images.push({ src: result?.subfamilyMediaUrl ?? "" });                    
                }
                if(result.dataSheets){
                    result.dataSheets.forEach(item => {
                        if(item.urlImgCut) images.push({ src: item.urlImgCut ?? ""})
                    })
                }

                setListImages(images);

            }).catch(err => err)
        }
    },[subfamily]);


    const backLevelPage = () => {
       history.push(`/${language}/${gamma}/${line}/${family}`);
    }

    // NOVO MÉTODO
    const generatePdf = async (dataSheetItem: DataSheetModelResponse) => {
        toast.success(`${t(`${contactForm}loading`, {ns: NameSpace.COMMON })}`, {
            position: 'bottom-left',
            duration: 20000,
            icon:  <CircularProgress sx={{ color: "#ffffff" }} />,
            style: {
                minWidth: '450px',
                minHeight: '50px',
                color: "#ffffff",
                backgroundColor: "#50C878",
                fontSize: "20px"
                },      
            });      

        if(dataSheetItem)
          {        
            downloadPdfReport(dataSheetItem, language);                
          }   
      };

      const downloadPdfReport = async (dataSheetItem: DataSheetModelResponse, language : string) => {
        if(dataSheetItem.id != null)
        {
            try{
                setIsLoading(true);
                setCountdown(10);
        
                const interval = setInterval(() => {
                    setCountdown((prev) => {
                        if (prev === 1) {
                            clearInterval(interval);
                            return 0;
                        }
                        return prev - 1;
                    });
                }, 1000);


                await DataSheetService.getDataSheetById(dataSheetItem.id, language)
                .then((res) => {
                     if (res.data){
                         setUrlToPdfGenerated(res.data.url);
                        handleClickOpen();

                        setTimeout(() => {
                            // generatePdf(item);
                            setIsLoading(false);
                            setCountdown(0); // Resetar após o download                            
                        }, 10000);

                    }
                });
                
                setIsLoading(false);

            } catch (error) {
                console.error("Erro ao baixar o pdf:", error);
            }
        }       
      };

    return (
        <>
         <Container  sx={{ maxWidth: "1200px" , height: "auto" , paddingTop: 12, paddingBottom: 15, overflowX: 'hidden'}}>
            <BreadcrumbPath
                ancestors={[
                {
                    name: gammaName,
                    path: gamma,
                },
                {
                    name: lineName,
                    path: line,
                },
                {
                    name: familyName,
                    path: family,
                },
                ]}
                className="pt-1"
                current={subfamily}                                                    
            />  
             <div>
                <button 
                className="btn btn-info mb-3"                
                onClick={() => backLevelPage()}            
                ><MdArrowBack className="variant-wrapper-tabs-button-prev-icon" /></button>
            </div>
            <Box className="container1" display={'flex'} width={'100%'}>  
                <Typography component={'h2'} variant="h6" sx={{ textTransform: "uppercase" , color: "#8B939F", mb: 2}}>
                    {subfamilyDataSheet?.familyName}
                </Typography>
                <Typography
                    className="title"
                    component={'h1'}
                    variant={'h1'}
                    sx={{
                    textTransform: "uppercase" ,
                    color: "#28487A",
                    fontSize: "25px",
                    fontWeight: 700
                    }}
                >
                    {subfamilyDataSheet?.subfamilyName}
                </Typography>

                <hr style={{ margin: 0, padding: 0 }}/>
                <Box display={'flex'} justifyContent={'center'} paddingY={3}>
                    <img
                        src={subfamilyDataSheet?.subfamilyMediaUrl}
                        alt=""
                        style={{ width: 400, maxWidth: "450px", cursor: "pointer"}}
                        onClick={() => openImageViewer()}

                    />
                </Box>
              
                <Box className="sibiling2" width={'50%'}>
                    <Box display={''} alignItems={"center"}>
                            <Box sx={{  maxHeight: '80px !important'}}>
                                <Typography component={'p'} variant="body2" sx={{ fontSize: "18px", textTransform: "uppercase"}} align="center">
                                        {t("selectModel", {ns: NameSpace.NAVIGATION }) }
                                </Typography>
                                <div className="swiper-arrow-subfamily-root">
                                    <div className="swiper-arrow-subfamily">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </Box>                           
                          {subfamilyDataSheet?.dataSheets?.length ? (
                                <TabSubFamilyDataSheetsMobile 
                                subfamilyDataSheet={subfamilyDataSheet}
                                askInformation={askInformation}
                                generatePdf={generatePdf}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                countdown={countdown}
                                setCountdown={setCountdown}
                                urlToPdfGenerated={urlToPdfGenerated}
                                hover={hover}
                                setHover={setHover}
                                />
                            ) : null} 
                                                        
                        </Box>                       
                    </Box>
                </Box>
            <hr />
            <Box className="contentProduct" width={'100%'}>                   
               {
                        hasTable === true && 
                        <Box className="product-image" display={'block'} paddingLeft={5} paddingTop={0}>
                            <Box display={''} alignItems={"center"}>                           
                                <Typography
                                className="subtitle"
                                component={'h2'}
                                variant="h2"
                                align="center"
                                sx={{
                                    textTransform: "uppercase" ,
                                    color: "#345C8D",
                                    fontSize: "25px",
                                    fontWeight: 400,
                                    paddingTop: "14px"
                                }}
                                >
                                    {t("technicalInformation", { ns: NameSpace.NAVIGATION})}
                                </Typography>
                                <hr style={{ margin: '10px 0 10px 0' }}/>
                                <Box
                                sx={{ width: '100%', marginTop: '15px !important', justifyContent: 'center', textAlign: 'center', display: 'flex', mb: 1}}
                                >
                                    <div
                                        className={`${style.content_table} ${style.backgroundImageColor} table_container`}
                                    >
                                        <div id="tableId"
                                            className={`${style.table} ${style.backgroundImageColor}`}
                                        >
                                        </div>
                                        <Typography component={'h2'} variant="h6" sx={{ textTransform: "uppercase" , color: "#8B939F", mb: 2}}>
                                            {subfamilyDataSheet?.familyName}
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    } 
                    
            </Box>           
         </Container>
         <ContactSpecificForm
              // dataSheetProduct={dataSheet}
              product={subfamilyDataSheet}
              className="mt-10"
              ref={contactFormRef}
          /> 
            <MediaViewer
            visible={visible}
            onClose={closeImageViewer}            
            activeIndex={activeImageIndex}
            images={listImages}
            />        
              <OpenPdfModalDialog open={open} handleClose={handleClose} urlPdfToGo={urlToPdfGenerated} />
        </>

    )
}

export default SubfamilyMobile;


const buildingTable = (data: DataSheetFeatureWithTranslation[], languages: string) => {

    const table = document.createElement('table');
    table.classList.add(style.responsiveTable);


    // if(data && data.length === 0){
    //     const existingElement = document.getElementById('tableId');
    //     if(existingElement) existingElement.remove();
    // }
          
     const uniqueModels = [...new Set(data.map(item => item.feature.model))];
     uniqueModels.sort((a, b) => a - b);         
    
     const headerRow = document.createElement('tr');
 
     
     const languageToKeyword = {
         "PT": "MODELO",
         "EN": "MODEL",
         "FR": "MODÈLE",
         "ES": "MODELO",
     };
 
     
     const languageResult = languageToKeyword[languages] || "Model"; 
      
     const emptyHeaderCell = document.createElement('th');
     emptyHeaderCell.textContent = languageResult;
     emptyHeaderCell.classList.add(style.greentext);
     emptyHeaderCell.style.textAlign = 'center';
     emptyHeaderCell.style.fontSize = '11px';
     headerRow.appendChild(emptyHeaderCell);
 
     uniqueModels.forEach(model => {
         const headerCell = document.createElement('th');
         headerCell.textContent = model;
         headerCell.style.textAlign = 'center';
         headerCell.style.fontSize = '11px';
         
         headerCell.classList.add(style.tableHeader);
         headerRow.appendChild(headerCell);
     });
      
     table.appendChild(headerRow);
  
     const addedTranslations = new Set();
  
     data.forEach(item => {
         const translation = item.translations[0]?.name; 
          
         if (!addedTranslations.has(translation)) {
             const translationRow = document.createElement('tr');
 
             const translationCell = document.createElement('td');
             translationCell.style.fontSize = '11px';
             translationCell.textContent = translation;
             translationRow.appendChild(translationCell);
 
             uniqueModels.forEach(model => {
                 const matchingItem = data.find(d => d.translations[0]?.name === translation && d.feature.model === model);
                 let value = matchingItem ? matchingItem.feature.value || '-' : '-';
 
                 const valueCell = document.createElement('td');
                 valueCell.style.textAlign = 'center';
                 valueCell.style.fontSize = '11px';
                  
                 if (matchingItem && (matchingItem.feature.id.includes("Mec-06") || matchingItem.feature.id.includes("Mir-06") || matchingItem.feature.id.includes("06"))) {
                     const imageElement = document.createElement('img');
                     imageElement.src = `https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/${value}`;
                     imageElement.width = 60;
                     valueCell.appendChild(imageElement);
                 } else {
                     valueCell.textContent = value;
                 }
                 translationRow.appendChild(valueCell);
             }); 
             
             table.appendChild(translationRow);
              
             addedTranslations.add(translation);
         }
     });
 
  
     const tableContainer = document.getElementById('tableId');
     if (tableContainer) {
         tableContainer.innerHTML = '';
     }
   
     const tableContainerNew = document.getElementById('tableId');
     if (tableContainerNew) {
        tableContainerNew.appendChild(table);
     }
};