// Verificar se possui a role para aquele fim, ex Admin para rotas com Admin role required
/**
 * Função para verificar se o usuário tem uma role específica.
 * @param roleRequired Role exigida
 * @returns {boolean}
 */

import { jwtDecode } from "jwt-decode";
import JwtModel from "../models/JwtModel";

export const hasRole = (roleRequired: string): boolean => {
    const token = localStorage.getItem("token");

    if(!token) return false;

    try {
        const decodedToken: JwtModel = jwtDecode(token);
        const userRoles: string[] | string = decodedToken.role || [];

        const rolesArray = Array.isArray(userRoles) ? userRoles : [userRoles];

        return rolesArray.includes(roleRequired);

    } catch (error) {
        console.error("Error ao decodificar o token", error);
        return false;
    }
};