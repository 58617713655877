import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import AuthService from '../../services/api/Auth';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';
import FeedbackDialog from '../FeedbackDialog/FeedbackDialog';

interface ForgotPasswordProps {
  open: boolean;
  handleClose: () => void;
}

export default function ForgotPassword({ open, handleClose }: ForgotPasswordProps) {
  const [ email, setEmail ] = React.useState<string>('');
  const [ emailError, setEmailError ] = React.useState<boolean>(false);
  const [ emailErrorMessage, setEmailErrorMessage ] = React.useState<string>('');
  const [ isValidToSubmit, setIsValidToSubmit ] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [feedbackState, setFeedbackState] = React.useState({
    success: false,
    message: '',
  });

  React.useEffect(() => {
    if(emailError){
      setIsValidToSubmit(false);
    }
    if(!emailError){
      
    }
  },[emailError]);


  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue =  event.target.value;
    setEmail(emailValue);    

    if(!emailValue || !/\S+@\S+\.\S+/.test(emailValue)){
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      setIsValidToSubmit(false);
    }
    else {
      setEmailError(false);
      setEmailErrorMessage('');
      setIsValidToSubmit(true);      
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(!isValidToSubmit) return;
    
    setIsValidToSubmit(false);

    await AuthService.forgotPassword(email).then((res) => {
      setFeedbackState({
        success: true,
        message: 'Your password has been successfully reset! You can now log in with your new password.',
      });
      // e chama mensagem de feedbackdialog dizendo que enviamos o email se existir
    })
    .catch(() => {
      setFeedbackState({
        success: false,
        message: 'We were unable to reset your password. Please try again or request a new reset link.',
      });          
    })
    .finally(() => {
      setDialogOpen(true);
      setIsValidToSubmit(true); // Reativa o botão após resposta do servidor
      handleClose();
    }); 
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              handleClose();
            },
            sx: { backgroundImage: 'none' },
          },
        }}
        >
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
        >
          <DialogContentText>
            Enter your account&apos;s email address, and we&apos;ll send you a link to
            reset your password.
          </DialogContentText>
        
                                  
            <OutlinedInput
              autoFocus
              required
              margin="dense"
              id="email"
              name="email"
              label="Email address"
              placeholder="Email address"
              type="email"
              fullWidth
              onChange={handleEmailChange}
              error={emailError}          
              color={emailError ? 'error' : 'primary'}
              />
            {emailError && <Typography sx={{ color: '#A52A2A' }} >  {emailErrorMessage}</Typography>}
        

          
        </DialogContent>
        <DialogActions sx={{ pb: 3, px: 3 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit" disabled={!isValidToSubmit} onClick={handleSubmit}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
       <FeedbackDialog
          open={dialogOpen}
          handleClose={() => {
            setDialogOpen(false);       
          }}
          success={feedbackState.success}
          message={feedbackState.message}
        />
    </>
  );
}