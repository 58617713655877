import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import RequestAccessCard from '../../components/Signin e RequestAccess/RequestAccessCard';



const SignUpContainer = styled(Stack)(({ theme }) => ({
  // height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',   
  },
}));

export default function RequestAccess() {

  return (
      <SignUpContainer direction="column" justifyContent="space-between" my={10}>
        <RequestAccessCard />
      </SignUpContainer>

  );
}