import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../../services/api/Auth';
import ResetPassword from './ResetPassword';
import ValidatingDialog from './ValidatingDialog';
import ResetTokenRequest from '../../services/types/ResetTokenRequest';

export interface ValidatingDialogProps {
  open: boolean;
  handleClose: () => void;
  success: boolean;
  message: string;
}


export default function ResetPasswordValidate(props?: ValidatingDialogProps) {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [validToken, setValidToken] = useState<boolean | null>(null);
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');
    
  const handleClose = () => {
    props?.handleClose();
  }
  

  useEffect(()  => {      
      const params = new URLSearchParams(location.search);
      const email = params.get('email');
      const token = params.get('token');

      if(!email || !token){
        setErrorMessage("Invalid link. Request a new password recovery.");
        return;
      }
            

      const resetTokenData: ResetTokenRequest =  {
        email,
        token
      }
      
 
      AuthService.validateResetToken(resetTokenData)
      .then(() => {
        setEmail(email);
        setToken(token);
        setValidToken(true)}
      )
      .catch(() => setErrorMessage("The password reset link has expired or is invalid."));

  }, [location.search]);  

  if (errorMessage) {
    return <ValidatingDialog handleClose={handleClose} message={errorMessage} open={true} success={false} /> 
  }

  if (validToken === null) {
    return <ValidatingDialog handleClose={handleClose} message={'Validating a your request !'} open={true} success={true}/>        
  }


  return <ResetPassword email={email} token={token} />;   
}