import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import LoginIcon from '@mui/icons-material/Login';
import { Nav, Navbar, Image } from "react-bootstrap";

import TeamNav from './Navigation';
import { Link } from 'react-router-dom';
import { MafirolIcon } from '../../../components/Signin e RequestAccess/CustomIcons';
import { User } from 'lucide-react';
import { AuthContext } from '../../../context/AuthContext';
import { useHistory } from "react-router-dom";
import AccountMenu from '../../../parts/MenuAuth/AccountMenu';
import { useTranslation } from 'react-i18next';
import Util from '../../../utils/Util';
import { NameSpace } from '../../../data/AppLanguage';
import UserDataModel from '../../../interfaces/User/UserData';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        data-screenshot="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default function Header() {
  const [open, setOpen] = React.useState(false);
    const { isAuthenticated, user } = React.useContext(AuthContext);  
    const [userLogged, setUserLogged] = React.useState<UserDataModel>();  
    const { t, i18n } = useTranslation([NameSpace.ROUTES]);
    const language = Util.splitLanguage(i18n.language, 0);

     React.useEffect(() => {
      setUserLogged(user);            
      },[user]);


  const { logout } = React.useContext(AuthContext);
    const history = useHistory();
    
  const handleLogout = () => {
    logout();
    history.push("/");
  }

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <Link to={"/"}>
            <IconButton
            size="md"
            variant="outlined"
            color="neutral"
            sx={{ display: { xs: 'none', sm: 'inline-flex' }, borderRadius: '50%' }}
            >
            <LanguageRoundedIcon />        
            </IconButton>
            <IconButton
            size="md"
            // variant="outlined"
            color="neutral"
            sx={{ display: { xs: 'none', sm: 'inline-flex' }, borderRadius: '8%', marginLeft: '10px' }}
            >          
            <MafirolIcon/>
            </IconButton>
        </Link>            
        <Button
          variant="plain"
          color="neutral"
          aria-pressed="true"
          component="a"          
          size="sm"
          sx={{ alignSelf: 'center' }}
        >
          Dashboard
        </Button>    
      </Stack>
      <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
          open={open}
          onClose={() => setOpen(false)}
        >
          <ModalClose />
          <DialogTitle>Acme Co.</DialogTitle>
          <Box sx={{ px: 1 }}>
            <TeamNav />
          </Box>
        </Drawer>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >     
        {window.innerWidth < 600
        &&
        
        <Tooltip title="Mafirol" variant="outlined">
          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            component="a"            
            sx={{ alignSelf: 'center' , mr: 5}}
            disabled
          >
            <MafirolIcon/>
          </IconButton>
        </Tooltip>
        }
               
        <ColorSchemeToggle />
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '44px', maxHeight: '32px', borderRadius: '9999999px' }}
          >
            <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                component="a"                
                sx={{ alignSelf: 'center' }}
            >
                <User style={{width: 44, color: 'gray'}} />            
            </IconButton>
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <User style={{width: 44, color: 'gray'}} />      
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    {userLogged?.fullName}
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    {userLogged?.email}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem disabled>
              <HelpRoundedIcon />
              Help
            </MenuItem>
            <MenuItem disabled>
              <SettingsRoundedIcon />
              Settings
            </MenuItem>
            <ListDivider />                       
            <MenuItem onClick={handleLogout}>
              <LogoutRoundedIcon />
              Log out 
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}