import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography, styled } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle2 } from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Util from '../../utils/Util';
import { NameSpace } from '../../data/AppLanguage';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LinearDeterminate from '../Progress/LinearDeterminate';

interface ResponseCardProps {
  open: boolean;  
  counter: number;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2),
    maxWidth: 400,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
  },
}));

const SuccessIcon = styled(motion.div)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 24,
});

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  padding: '10px 24px',
  textTransform: 'none',
  fontSize: 16,
  '&.MuiButton-containedPrimary': {
    backgroundColor: '#4CAF50',
    color: 'white',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
}));

export default function OpenResponseAccessModalDialog({ open, counter }: ResponseCardProps) {
  const { t, i18n } = useTranslation([ NameSpace.ROUTES ]);
  const language = Util.splitLanguage(i18n.language, 0);
  const history = useHistory();

  return (
    <AnimatePresence>
      {open && (
        <StyledDialog
          open={open}          
          PaperComponent={motion.div}
          PaperProps={{
            initial: { scale: 0.9, opacity: 0 },
            animate: { scale: 1, opacity: 1 },
            exit: { scale: 0.9, opacity: 0 },
            transition: { duration: 0.2 }
          }}
        >
          <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>
            <Typography variant="h5" component="div" fontWeight="bold" color="#2E7D32">
              Success!
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Box sx={{ textAlign: 'center', py: 3 }}>
              <SuccessIcon
                initial={{ scale: 0 }}
                animate={{ scale: 1, rotate: 360 }}
                transition={{ type: "spring", duration: 0.8 }}
              >
                <CheckCircle2 size={80} color="#4CAF50" strokeWidth={1.5} />
              </SuccessIcon>

              <Typography variant="body1" sx={{ mb: 2, color: '#666' }}>
                Request Access sent successfully!
              </Typography>
              
              <Typography variant="body2" color="text.secondary">
                You will receive an email from our team as soon as your account is activated!
              </Typography>
            </Box>            
            <div className="">
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                  Request submitted successfully! Redirecting to home...   {counter}s
              </Alert>          
              <LinearDeterminate />                
            </div>            
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', gap: 2, pb: 3 }}>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={() => history.push('/')}
              component={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Go to Home Page!
            </ActionButton>
            
            <ActionButton
              variant="outlined"
              onClick={() => history.push(`/${language}/signin`)}
              component={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Go to Login!
            </ActionButton>
          </DialogActions>
        </StyledDialog>
      )}
    </AnimatePresence>
  );
}