import React, { useEffect, useState } from 'react';
import { CBadge, CButton, CCardBody, CCollapse, CDataTable, CSpinner, CSwitch } from '@coreui/react';
import UserDataModel from '../../interfaces/User/UserData';
import { Chip, ListItemButton, ListItemContent, ListItemDecorator } from '@mui/joy';
import { Box, Grid, Table, Typography } from '@mui/material';
import AuthService from '../../services/api/Auth';
import styles from './Users.module.css';
import { Avatar } from '@mui/joy';
import { Switch } from '../Switch/Switch';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SendIcon from '@mui/icons-material/Send';
import CachedIcon from '@mui/icons-material/Cached';
import { RefreshCcw } from 'lucide-react';

import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Button from '@mui/joy/Button';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import BusinessIcon from '@mui/icons-material/Business';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GppGoodIcon from '@mui/icons-material/GppGood';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';




 interface Props {
  title: string;
 }

 
 const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

  const Users = (props: Props) =>{    
    const [checked, setChecked] = React.useState<boolean>(false);
    const [usersList, setUserList] = React.useState<UserDataModel[]>([]);    
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [userToRemove, setUserToRemove] = React.useState<UserDataModel | null>();


    useEffect(() => {
      AuthService.getGetAllUsers().then((res) => {
        setUserList(res.data);
      })
    },[checked]);


    const handleToggleActive = async (userId: string, isActive: string) => {
      // Atualiza a UI imediatamente para refletir a mudança      
        const isActiveChange = String(isActive) === 'Active' ? true : false;

        const updatedUsers = (usersList.map(user =>
          user.id === userId ? { ...user, isActive: !user.isActive } : user
        ));
        setUserList(updatedUsers);

        try {
            await handleChangeUserStatus(userId, !isActiveChange);
        } catch (error) {
          console.error("Erro ao atualizar status do usuário", error);
          setUserList(usersList);
        }
    };

    const handleChangeUserStatus = async (id: string, status: boolean) => {
      try {
        await AuthService.changeUserStatus(id, status).then((result) => {
          var data = result.data;
          setUserList(data);
        });
        // console.log(`Status atualizado com sucesso para ${status ? 'Ativo' : 'Inativo'}`);
      } catch (error) {
          console.error("Erro ao alterar status no backend:", error);
      }
    }

    const handleRemoveUser = (userId: string) => {
      setOpen(true);
      setUserToRemove(usersList.find(x => x.id === userId));
    };

    const confirmHandleRemoveUser = (userId: string) => {
      const userList = usersList;
      try {
        AuthService.removeUser(userId).then((result) => {
          setUserList(result.data);
        }).catch((error) => {
          setUserList(userList);
        })
      }  catch (error) {
        setUserList(userList);
        console.error("Erro ao tentar remover user:", error);
      }
    };


    

    useEffect(() => {
      AuthService.getGetAllUsers().then((res) => {
        setUserList(res.data);       
      })
      .catch((er) => er)
      .finally(() => setLoading(false))
    },[loading]);

    const updatedUsers = () => {
      setLoading(true);     
    }


    const usersListTable = usersList
    .sort((a, b) => {
      // 1️⃣ Ordena primeiro pelos inativos (Inactive primeiro)
      if (!a.isActive && b.isActive) return -1;
      if (a.isActive && !b.isActive) return 1;
  
      // 2️⃣ Ordena por data de criação (mais recentes primeiro)
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return dateB - dateA;
    })
    .map((user) => ({
      id: user.id,
      companyName: user.companyName,
      fullName: user.fullName,
      email: user.email,
      isActive: user.isActive ? "Active" : "Inactive",
      role: user.roles,
      customerCode: user.customerCode ? String(user.customerCode) : "-",
      createdAt: user.createdAt
        ? new Date(user.createdAt).toLocaleString("pt-PT").replace(",", "")
        : "-",
      activatedAt: user.activatedAt
        ? new Date(user.activatedAt).toLocaleString("pt-PT").replace(",", "")
        : "-",
    }));

    
    // }).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    const fields = [
      // { key: 'id', _style: { width: '40%'} , label: 'Id'},
      { key: 'createdAt', label: 'Registrado', filter: true, sorter: true},
      { key: 'companyName', _style: { width: '40%'} , label: 'Empresa', filter: false},
      { key: 'customerCode', _style: { width: '20%'} , label: 'Cód.Cliente', filter: true},
      { key: 'fullName', _style: { width: '40%'} , label: 'Nome', filter: false},
      { key: 'email', _style: { width: '40%'} , label: 'Email', filter: true},
      { key: 'isActive', _style: { width: '20%'} , label: 'Ativo ?', filter: true},
      { key: 'role', _style: { width: '20%'}, filter: false },
      { key: 'activatedAt', label: 'Ativado', filter: false},
      {
        key: 'allowed',
        label: 'Permitido/Negado',
        _style: { width: '1%' },
        sorter: true,
        filter: true
      },
      {
        key: 'delete',
        label: 'Excluir',
        _style: { width: '1%' },
        sorter: false,
        filter: false
      }
    ]
  
    
    const colors = ["#FFD700"];
    const randomColors = colors[Math.floor(Math.random() * colors.length)];

    return (
    <>
      <Box >
        <Typography variant='body2' fontSize={30} textAlign={'center'} color={'#808080'} my={2}>
          {props.title}
        </Typography>
            {loading ? (
              <>
                <CButton color="secondary" disabled
                style={{borderRadius: 9 ,
                  margin: '10px 0',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#000'
                }}
                >
                  <CSpinner as="span" className="me-2" size="sm" color='warning' aria-hidden="true" />
                  <span role="status">  Loading...</span>
                </CButton>             
              </>
            ) : (
              <>
                <CButton color="secondary" disabled={loading} 
                style={{ borderRadius: 9 ,
                margin: '10px 0',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#000'
                }}  
                onClick={updatedUsers}
                >
                  <RefreshCcw color='rgba(252, 227, 0, 0.94)' size={15} style={{ marginRight: 8 }}/>                
                  <span role="status" style={{ color: 'rgba(252, 227, 0, 0.94)'}}>  Atualizar Lista</span>
                </CButton>
              </>
            )}
            
        <Box sx={{ border: '1px solid rgba(211, 211, 211, 0.68)', borderRadius: '7px' , padding: 5 }}>
          <CDataTable
              items={usersListTable}
              fields={fields}
              columnFilter
              tableFilter
              // footer
              itemsPerPageSelect
              itemsPerPage={6}
              hover
              sorter            
              pagination
              scopedSlots = {{
                'companyName':
                    (item: UserDataModel)=>(
                      <td className={styles.td}>
                        <div className={styles.userContainer}>
                          <Avatar className={styles.avatar} sx={{ backgroundColor:  randomColors}}>{item.companyName.substring(0,2).toUpperCase()}</Avatar>                    
                          <div className={styles.companyName}>{item.companyName.toUpperCase()}</div>
                        </div>
                      </td>    
                    ),
                    'isActive':
                    (item: any)=>(
                      <td className={styles.td}>
                                        <span className={`${styles.status} ${item.isActive === 'Active' ? styles.active : styles.inactive}`}>
                                          {item.isActive}
                                        </span>
                                      </td>
                    ),
                    'allowed':
                  (item: any, index: number)=>{
                      return (
                        <>
                          <td className={`${styles.td} ${styles.textRight}`}>
                            <Switch checked={item.isActive === 'Active' ? true : false} onChange={() => handleToggleActive(item.id, item.isActive)} />
                          </td>                       
                        </>
                      )
                    },        
                  'delete':
                  (item: UserDataModel, index: number)=>{
                    return (
                      <>                      
                          <td className={`${styles.td} ${styles.textRight}`}>
                            {/* <Switch checked={item.isActive} onChange={() => handleToggleActive(item.id)} /> */}
                            <DeleteForeverIcon  sx={{fontSize: 32, color: 'red', cursor: 'pointer'}} onClick={() => handleRemoveUser(item.id)}/>
                          </td>
                        </>
                      )
                  },        
              }}
              />
        </Box>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmação
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ minWidth: '500px !important'}}>
            Tem certeza que deseja excluir essa conta deste cliente?
                      
            <List>
              <ListItem>                
                  <ListItemDecorator><SupervisorAccountIcon /></ListItemDecorator>
                  <ListItemContent>Utilizador</ListItemContent>                
                  <ListItemContent sx={{ textAlign: 'right', paddingRight: '25px' }}>{userToRemove?.fullName}</ListItemContent>                
              </ListItem>
              <ListItem>                
                      <ListItemDecorator><BusinessIcon /></ListItemDecorator>
                      <ListItemContent>Empresa</ListItemContent>                
                      <ListItemContent sx={{ textAlign: 'right', paddingRight: '25px' }}>{userToRemove?.companyName}</ListItemContent>                
              </ListItem>
              <ListItem>                
                  <ListItemDecorator><EmailIcon /></ListItemDecorator>
                  <ListItemContent>Email</ListItemContent>                
                  <ListItemContent sx={{ textAlign: 'right', paddingRight: '25px' }}>{userToRemove?.email}</ListItemContent>                
              </ListItem>
              <ListItem>                
                  <ListItemDecorator><CalendarMonthIcon /></ListItemDecorator>
                  <ListItemContent>Data de Registo</ListItemContent>                
                  <ListItemContent sx={{ textAlign: 'right', paddingRight: '25px' }}>
                    {
                      userToRemove?.createdAt ? new Date(userToRemove?.createdAt).toLocaleString("pt-pt").replace(",", '') : "-"
                    }
                  </ListItemContent>                
              </ListItem>
              <ListItem>                
                  <ListItemDecorator><CalendarMonthIcon /></ListItemDecorator>
                  <ListItemContent>Data de Ativação</ListItemContent>                
                  <ListItemContent sx={{ textAlign: 'right', paddingRight: '25px' }}>
                    {                      
                      userToRemove?.activatedAt ? new Date(userToRemove?.activatedAt).toLocaleString("pt-pt").replace(",", '') : "-"
                    }
                  </ListItemContent>                
              </ListItem>
              <ListItem>                
                  <ListItemDecorator>{userToRemove?.isActive ? <GppGoodIcon /> : <RemoveCircleOutlineIcon/>}</ListItemDecorator>
                  <ListItemContent>Ativo ? </ListItemContent>                
                  <ListItemContent sx={{ textAlign: 'right', paddingRight: '25px' }}>{userToRemove?.isActive}</ListItemContent>                
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => { 
              confirmHandleRemoveUser(userToRemove?.id ?? "")
              setOpen(false)}
              }>
              Excluir Conta
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
    )
};

export default Users;