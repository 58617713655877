import { createContext, ReactNode, useEffect, useState } from "react";
import { AuthContextType } from "../interfaces/AuthContextType";

import i18n from "../i18n";
import Util from "../utils/Util";
import { jwtDecode } from "jwt-decode";
import UserDataModel from "../interfaces/User/UserData";
import AuthService from "../services/api/Auth";
import { useHistory } from "react-router";
import {  } from "react-router-dom";
import JwtModel from "../models/JwtModel";
import { hasRole } from "../utils/AuthUtils";



export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode}) => {
    const [user, setUser] = useState<UserDataModel | null>(null); //colocar aqui UserModel
    const [token, setToken] = useState<string | null>(localStorage.getItem("token"));
    const history = useHistory();
    const [language, setLanguage] = useState<string>("pt"); // Defina um idioma padrão
    

    const historyGO = (pathLocation: string) => {
        // console.log("Redirecionando para historyGO:", pathLocation);

        history.push(pathLocation);
    }

    useEffect(() => {
        if (i18n.language) {
          setLanguage(Util.splitLanguage(i18n.language, 0));
        }
      }, [i18n.language]);

    useEffect(() => {
        if(token){
            if (isTokenExpired(token)){
                logout();
            } else {
                fetchUser();
            }
        }
    },[token]);

    
    const fetchUser = async () => {
        try {
            // if(user === null) Buscar user se for null, só refletirá mudança ao sair ou se user for null, caso contrário usa o user que já tem
            const userId = getUserIdFromToken(token);

            // console.log('cheguei no fecthuser e meu id é:',  userId);
            
            if (userId === null || userId === "") {
                // logout();
                // console.log("disse que meu id é nulo, entretanto id é : ", userId);
                return;
            }

            if(userId)
            {
                const result = await AuthService.getUserById(userId);
                if (result.status === 200) {
                    setUser(result.data);
                }
            }
            // else {
            //     logout();
            // }
            
        } catch {
            // logout();
            // console.log('entrou no cath de fecthuser');
            
        }
    };
    
    const login = (jwtToken: string, returnUrl?: string, closeModal?: () => void) => {
        // console.log('chegou no contexto global provided LOGIN', jwtToken);
        
        // Salvar o token no localStorage e no estado global
        localStorage.setItem("token", jwtToken);
        setToken(jwtToken);

        try {
            fetchUser();
            // console.log("returnUrl em login", returnUrl);
            
            // Decodificar o jwt baseado na jwtmodel e não no padrão para fazer o redirect correto
            const decodedToken: JwtModel = jwtDecode(jwtToken);
            // console.log("Token decodificado", decodedToken);

            // Verifica se o user tem a role "Admin"
            const userRoles: string[] | string = decodedToken?.role || []; 
            // console.log('o user roles em login() é : ', userRoles)

            const isAdmin = userRoles.includes("Admin");
            // console.log('isAdmin é : ', isAdmin);


            // Determina para onde redirecionar
            
            // Se houver um `returnUrl`, usa ele, senão define conforme a role
            let redirectTo = returnUrl && returnUrl !== "/" ? returnUrl : isAdmin ? `/${language}/admin/dashboard` : `/${language}`;

            // console.log("Redirecionando para:", redirectTo);
             // Se o login veio de uma modal, apenas fecha a modal
        if (closeModal) {
            closeModal();
        } else {
            historyGO(redirectTo);
        }

        } catch (error) {

            // console.error("Erro ao decodificar o token", error);
            //Se houver erro no decode, redireciona para login
            historyGO(`/${language}/signin`);
        }
       
    };
    
    
    const logout = () =>{
        // alert('chegou no ogout context')
        localStorage.removeItem("token");
        setUser(null);
        setToken(null);
        // console.log('passei aqui no logout');

        
        // historyGO(`/${language}`);
    };
    
    const isTokenExpired = (token: string) => {
        try {
          const payload = JSON.parse(atob(token.split(".")[1]));
          const exp = payload.exp * 1000; // Convertendo para timestamp em milissegundos
          return Date.now() >= exp
        } catch {
            return true;
        }
    };
    
    const isAuthenticated = !!token && !isTokenExpired(token);


    const getUserIdFromToken = (token: string | null): string | null => {
        if (!token) return null;
        try {
            const decoded = jwtDecode(token);
            // const payload = JSON.parse(atob(token.split(".")[1]));
            return decoded.sub || null; // Assume que o ID do usuário está no campo `sub`
        } catch {
            return null;
        }
    };

    const isAdmin = () => {
        return hasRole("Admin");
    }

    return(
        <AuthContext.Provider value={{ user, token, isAuthenticated, login, logout, isAdmin }}>
            {children}
        </AuthContext.Provider>
    );
};

// ESTRUTURA ESPERADA NO TOKEN 
// {
//     "sub": "05d04dcd-efb2-41da-af45-950be17dfd56",
//     "email": "admin@maf.com",
//     "nbf": 1740766489,
//     "exp": 1740770089,
//     "iat": 1740766489,
//     "iss": "api.mafirol.info",
//     "aud": "api.mafirol.info"
//   }

/*
    CRIAR MODEL DE USER PARA PREENCHER
    ID
    NOME
    EMAIL
    COMPANY
    ROLE (LISTA) ["Admin" | "Customer" | ]
*/