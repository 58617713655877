import { Dialog, DialogContent, DialogActions, Typography, styled } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle2, House, KeyRound, LoaderPinwheel, ShieldAlert, XCircle } from 'lucide-react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Util from '../../utils/Util';
import { NameSpace } from '../../data/AppLanguage';
import { useTranslation } from 'react-i18next';



export interface FeedbackDialogProps {
    open: boolean;
    handleClose: () => void;
    success: boolean;
    message: string;
  }


const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2),
    maxWidth: 400,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
  },
}));

const ActionButtonHome = styled(motion.button)({
  padding: '10px 24px',
  borderRadius: 8,
  border: 'none',
  backgroundColor: '#4CAF50',
  color: 'white',
  fontSize: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#45a049',
  },
});



const ActionButtonSignin = styled(motion.button)({
  padding: '10px 24px',
  borderRadius: 8,
  border: 'none',
  backgroundColor: '#4CAF50',
  color: 'white',
  fontSize: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#45a049',
  },
});


const IconWrapper = styled(motion.div)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 24,
});

export default function ValidatingDialog({ open, handleClose, success, message }: FeedbackDialogProps) {
  const history = useHistory();
    const { t, i18n } = useTranslation([NameSpace.ROUTES]);
    const language = Util.splitLanguage(i18n.language, 0);
  return (
    <AnimatePresence>
      {open && (
        <StyledDialog
          open={open}
          onClose={handleClose}
          PaperComponent={motion.div}
          PaperProps={{
            initial: { scale: 0.9, opacity: 0 },
            animate: { scale: 1, opacity: 1 },
            exit: { scale: 0.9, opacity: 0 },
            transition: { duration: 0.2 }
          }}
        >
          <DialogContent>
            <div className="text-center py-6">
              {success ? (
                <IconWrapper
                initial={{ scale: 0.9 }}
                animate={{ rotate: 360 }}
                transition={{ repeat: Infinity , ease: 'linear', duration: 1 }}
                >
                  <LoaderPinwheel size={80} color="#ffcc00" strokeWidth={1.5} />
                </IconWrapper>
              ) : (
                <IconWrapper
                  initial={{ scale: 0.9 }}
                  animate={{ rotate: 0 }}
                  transition={{ ease: 'linear', duration: 1 }}
                >
                  <ShieldAlert  size={80} color="#ffcc00" strokeWidth={1.5} />
                </IconWrapper>
                )              
              }
              
              
            

              <Typography variant="h5" component="div" fontWeight="bold" 
                color={"#ffcc00"} 
                className="mb-4"
              >
                {message}
              </Typography>

              <Typography variant="body1" className="mb-6 text-gray-600">
                {success ? 'Just a moment please!' : ''}
              </Typography>
            </div>
          </DialogContent>         
          {!success &&          
            <DialogActions className="flex justify-content-center pb-6">
              <ActionButtonHome
                onClick={() => history.push('/')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ backgroundColor:  '#fff', color: 'green', border: '1px solid green' }}
              >              
                <House  size={20} color="#ffcc00" strokeWidth={1.5} style={{ marginRight: 5 }}/>
                  Home
              </ActionButtonHome>
              <ActionButtonSignin
                onClick={() => history.push(`/${language}/signin`)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ backgroundColor:  '#fff', color: 'blue', border: '1px solid blue' }}
              >
                <KeyRound size={20} color="#ffcc00" strokeWidth={1.5} style={{ marginRight: 5 }}/>
                  Signin
              </ActionButtonSignin>
            </DialogActions>
          }
        </StyledDialog>
      )}
    </AnimatePresence>
  );
}