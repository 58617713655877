import { FamilyModel } from "../../models/FamilyModel";
import { TypeModel } from "../../models/TypeModel";

import MainTitle from "../MainTitle/MainTitle";
import ContactPhoneForm from "../ContactPhoneForm/ContactPhoneForm";
import BreadcrumbPath from "../BreadcrumbPath/BreadcrumbPath";
import FamilyDescription from "../FamilyDescription/FamilyDescription";
import { Col, Container, Row } from "react-bootstrap";
import CategoryItem from "../CategoryItem/CategoryItem";

import "./MobileFamily.css";
import DataSheetService from "../../services/api/DataSheetService";
import Util from "../../utils/Util";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import DataSheetEntitie from "../../models/DataSheetEntitie";

interface Props {
  family?: FamilyModel;
  downloadDocument: (item: TypeModel) => void;
  askInformation: () => void;
  className?: string;
}

const MobileFamily = (props: Props) => {
  const language = Util.splitLanguage(i18n.language, 0);
  const[dataSheets, setDataSheets] = useState<DataSheetEntitie[]>([]);
  const [familyId, setFamilyId] = useState<any>()
  
  useEffect(() => {

    setFamilyId(props.family?.id)
  })

  useEffect(() =>{   
    if(props.family?.id) {
      DataSheetService.getDataSheetsByFamily(familyId, language.toUpperCase()).then((res) =>{
        setDataSheets(res.data);
      })  
    }           
    },[familyId])
 

  return (
    <>
      <MainTitle media={props.family?.media} height="70vh" />
      {/* <ContactPhoneForm /> */}
      <BreadcrumbPath
        ancestors={props.family?.ancestors}
        current={props.family?.name}
      />
      <Container>
        <FamilyDescription
          articleClassName="mt-2"
          family={props.family}
          askInformationColSpec={{ xs: 12, sm: 6, md: 4 }}
          documentsColSpec={{ xs: 12, sm: 6, md: 4 }}
          section2ColSpec={{ md: 7 }}
          section3ColSpec={{ md: 7 }}
          downloadDocument={props.downloadDocument}
          askInformation={props.askInformation}
        
          dataSheets={dataSheets}
        />
      </Container>
      <Container className="mt-7">
        <Row>
          {props.family?.subfamilies.map((item, index) => {
            return (
              <Col
                xs={12}
                sm={6}
                className="mobile-family-subfamilies-col p-0"
                key={index}
              >
                <CategoryItem
                  primaryMedia={item.primaryMedia}
                  secondaryMedia={item.secondaryMedia}
                  name={item.name}
                  energyEfficiencyMedia={item.energyEfficiencyMedia}
                  style={{
                    nameClassName: "text-center text-uppercase",
                    scaleImage: true,
                    background: true,
                  }}
                  key={index}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default MobileFamily;
