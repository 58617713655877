import { useState, useEffect } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MenuSubNavModel } from "./models/MenuSubNavModel";

import { NameSpace } from "./data/AppLanguage";

import InjectAxiosInterceptors from "./utils/InjectAxiosInterceptors";
import ScrollToTop from "./utils/ScrollToTop";
import Util from "./utils/Util";

import { localePath, routes } from "./data/Routes";

import { setErrorMessage } from "./features/error/errorSlice";
import { useAppDispatch } from "./app/hooks";

import NavigationBar from "./parts/NavigationBar/NavigationBar";
import Newsletter from "./parts/Newsletter/Newsletter";
import Footer from "./parts/Footer/Footer";

import Menu from "./parts/Menu/Menu";

import ErrorMessage from "./components/ErrorMessage/ErrorMessage";

import "bootstrap/dist/css/bootstrap.min.css";
import ProtectedRoute from "./data/ProtectedRoute";

const App = () => {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [mobileSubNav, setMobileSubNav] = useState<MenuSubNavModel | null>(
    null
  );

  const { t, i18n } = useTranslation([NameSpace.ROUTES]);
  const language = Util.splitLanguage(i18n.language, 0);
  const errorPath = t("error");

  const dispatch = useAppDispatch();
  const location = useLocation();

  const isAdminRoute = location.pathname.startsWith(`/${language}/admin`);

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(setErrorMessage({ statusCode: 0, message: "" }));

      if (open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }

      window.addEventListener("resize", handleWindowSizeChange);
    }

    return () => {
      mounted = false;

      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [dispatch, open]);

  return (
    <>
      <ErrorMessage />
      <InjectAxiosInterceptors />
      <ScrollToTop />
      {!isAdminRoute && location.pathname !== errorPath ? (
        <header>
          <NavigationBar
            open={open}
            setOpen={setOpen}
            windowWidth={windowWidth}
            mobileSubNav={mobileSubNav}
          />
        </header>
      ) : null}
      <main role="main">
        <section>
          {location.pathname !== errorPath ? (
            <nav>
              <Menu
                open={open}
                setOpen={setOpen}
                windowWidth={windowWidth}
                mobileSubNav={mobileSubNav}
                setMobileSubNav={setMobileSubNav}
              />
            </nav>
          ) : null}
          <Switch>
            {routes.map((item, index) => {
              var routes = Util.getRoutesPath(item.routes);
              const fullPath = `${localePath}${routes || item.path ? "/" : ""}${routes}${item.path ? item.path : ""}`;

              if(item.isProtected){
                return <ProtectedRoute key={index} exact={item.exact} path={fullPath} element={item.component} rolesRequireds={item.rolesRequireds}/>;
              }

              return (
                <Route key={index} exact={item.exact} path={fullPath} component={item.component}/>
              );
            })}
            <Redirect to={`/${language}`} />
          </Switch>
        </section>
      </main>
      {location.pathname !== errorPath ? (
        <footer>
          {/* <Newsletter /> */}
          <Footer />
        </footer>
      ) : null}
    </>
  );
};

export default App;
