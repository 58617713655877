import { Dialog, DialogContent, DialogActions, Typography, styled } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle2, KeyRound, XCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NameSpace } from '../../data/AppLanguage';
import Util from '../../utils/Util';



export interface FeedbackDialogProps {
    open: boolean;
    handleClose: () => void;
    success: boolean;
    message: string;
  }


const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2),
    maxWidth: 400,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
  },
}));

const ActionButton = styled(motion.button)({
  padding: '10px 24px',
  borderRadius: 8,
  border: 'none',
  backgroundColor: '#4CAF50',
  color: 'white',
  fontSize: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#45a049',
  },
});


const ActionButtonSignin = styled(motion.button)({
  padding: '10px 24px',
  borderRadius: 8,
  border: 'none',
  backgroundColor: '#4CAF50',
  color: 'white',
  fontSize: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#45a049',
  },
});



const IconWrapper = styled(motion.div)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 24,
});

export default function FeedbackDialog({ open, handleClose, success, message }: FeedbackDialogProps) {
 const history = useHistory();
    const { t, i18n } = useTranslation([NameSpace.ROUTES]);
    const language = Util.splitLanguage(i18n.language, 0);  
  return (
    <AnimatePresence>
      {open && (
        <StyledDialog
          open={open}
          onClose={handleClose}
          PaperComponent={motion.div}
          PaperProps={{
            initial: { scale: 0.9, opacity: 0 },
            animate: { scale: 1, opacity: 1 },
            exit: { scale: 0.9, opacity: 0 },
            transition: { duration: 0.2 }
          }}
        >
          <DialogContent>
            <div className="text-center py-6">
              <IconWrapper
                initial={{ scale: 0 }}
                animate={{ scale: 1, rotate: 360 }}
                transition={{ type: "spring", duration: 0.8 }}
              >
                {success ? (
                  <CheckCircle2 size={80} color="#4CAF50" strokeWidth={1.5} />
                ) : (
                  <XCircle size={80} color="#f44336" strokeWidth={1.5} />
                )}
              </IconWrapper>

              <Typography variant="h5" component="div" fontWeight="bold" 
                color={success ? "#2E7D32" : "#d32f2f"} 
                className="mb-4"
              >
                {success ? "Success!" : "Error"}
              </Typography>

              <Typography variant="body1" className="mb-6 text-gray-600">
                {message}
              </Typography>
            </div>
          </DialogContent>

          <DialogActions className="flex justify-center pb-6">
            {success && (
                <ActionButtonSignin
                onClick={() => { 
                  history.push(`/${language}/signin`);
                  handleClose();
              }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ backgroundColor:  '#fff', color: 'blue', border: '1px solid blue' }}
              >
                <KeyRound size={20} color="#ffcc00" strokeWidth={1.5} style={{ marginRight: 5 }}/>
                  Signin
              </ActionButtonSignin>
            )}
            <ActionButton
              onClick={handleClose}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{ backgroundColor: success ? '#4CAF50' : '#f44336' }}
            >
              Close
            </ActionButton>
          </DialogActions>
        </StyledDialog>
      )}
    </AnimatePresence>
  );
}