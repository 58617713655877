import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useHistory } from 'react-router-dom';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { FacebookIcon, GoogleIcon, MafirolIcon } from './CustomIcons';
import style from './RequestAccessCard.module.css';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LinearDeterminate from '../Progress/LinearDeterminate';
import RequestAccess from '../../interfaces/RequestAccess';
import AuthService from '../../services/api/Auth';
import jsonToFormData from "@ajoelp/json-to-formdata";
import { useTranslation } from 'react-i18next';
import Util from '../../utils/Util';
import OpenResponseAccessModalDialog from './OpenResponseAccessModalDialog';



const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
//   ...theme.applyStyles('dark', {
//     boxShadow:
//       'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
//   }),
}));

export default function RequestAccessCard() {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');

  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = React.useState('');

  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');

  const [companyNameError, setCompanyNameError] = React.useState(false);
  const [companyNameMessage, setCompanyNameErrorMessage] = React.useState('');

  const [customerCode, setCustomerCode] = React.useState<string>();
  const [customerCodeError, setCustomerCodeError] = React.useState(false);
  const [customerCodeErrorMessage, setCustomerCodeErrorMessage] = React.useState('');
  
  const [validateInputsResult, setValidateInputsResult] = React.useState(true);
  const [requestAccess, setRequesAccess] = React.useState<RequestAccess>();
  const [serverErrors, setServerErrors] = React.useState<string[]>([]);
  const [success, setSuccess] = React.useState(false);
  const history = useHistory();
    
  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);
   const [countdown, setCountdown] = React.useState(0);

  React.useEffect(() => {
    if (nameError || companyNameError || emailError || passwordError || confirmPasswordError || customerCodeError) {      
      setValidateInputsResult(true);
    } 
    if (!nameError && !companyNameError && !emailError && !passwordError && !confirmPasswordError && !customerCodeError) {      
      setValidateInputsResult(false);
    } 
  },[nameError, companyNameError, emailError, passwordError, confirmPasswordError, customerCodeError])

 
  const validateInputs = () => {
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;
    const confirmPassword = document.getElementById('confirmPassword') as HTMLInputElement;
    const fullName = document.getElementById('fullName') as HTMLInputElement;
    const companyName = document.getElementById('companyName') as HTMLInputElement;
    const customerCode = document.getElementById('customerCode') as HTMLInputElement;
    
    

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }
   
    if (confirmPassword.value !== password.value || confirmPassword.value.length < 6) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorMessage(`Passwords don't match`);
      isValid = false;
    } else {
      setConfirmPasswordError(false);
      setConfirmPasswordErrorMessage('');
    }

    if (!fullName.value || fullName.value.length < 6) {
      setNameError(true);
      setNameErrorMessage('fullName is required.');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }
    
    if (!companyName.value || companyName.value.length < 3) {
      setCompanyNameError(true);
      setCompanyNameErrorMessage('Company name is required.');
      isValid = false;
    } else {
      setCompanyNameError(false);
      setCompanyNameErrorMessage('');
    }
    
    if(!/^\d+$/.test(customerCode.value)){
      setCustomerCodeError(true);
      setCustomerCodeErrorMessage('Customer Code must contain only numbers.');
      isValid = false;
    }
    else if (!customerCode.value.length || customerCode.value.length < 6 || customerCode.value.length > 7) {
      setCustomerCodeError(true);
      setCustomerCodeErrorMessage('Customer Code is required (min 6 and max 7 number(s)).');
      isValid = false;
    } else {
      setCustomerCodeError(false);
      setCustomerCodeErrorMessage('');
    }

    return isValid;
  };

  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setServerErrors([]); // Limpa os erros anteriores
    setValidateInputsResult(true);

    if (nameError || companyNameError || emailError || passwordError || confirmPasswordError || customerCodeError) {
      event.preventDefault();
      return;
    }

    const formData = new FormData(event.currentTarget);

    const requestData: RequestAccess = {
      fullName: String(formData.get('fullName')),
      companyName: String(formData.get('companyName')),
      lastName: String(formData.get('lastName')),
      email: String(formData.get('email')),
      password: String(formData.get('password')),
      confirmPassword: String(formData.get('confirmPassword')),
      customerCode: Number(formData.get('customerCode')),
    };
    

    formData.append("fullName", requestData.fullName);
    formData.append("companyName", requestData.companyName);
    formData.append("email", requestData.email);
    formData.append("password", requestData.password);
    formData.append("confirmPassword", requestData.confirmPassword);
    formData.append("customerCode", String(requestData.customerCode));
    formData.append("languageId", language);

  
    if(requestData){
      // chamar serviço passando mdoel
      AuthService.requestAccess(formData).then((result) =>{
        const statusOk = result.status;
        setValidateInputsResult(true);

        if(statusOk === 200)
        {
          setCountdown(15);
          setSuccess(true);
    
          const interval = setInterval(() => {
            setCountdown((prev) => {
                if (prev === 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1;
            });
          }, 1500);
          
          setTimeout(() => {
            history.push('/');
          }, 20000)
        }        
      }).catch((error: any) => { 
        
        // console.log('error', error);
        // console.log('error.response', error.response);
        setValidateInputsResult(false);

        if (error.response) {
          // Se o backend retornou erros de validação, capturamos aqui
          if (Array.isArray(error.response.data)) {
              setServerErrors(error.response.data.map((err: any) => err.description || err.errorMessage || err)); 
          } else {
              setServerErrors([error.response.data || "Erro desconhecido"]);
          }
        } else {
            setServerErrors(["Erro ao conectar com o servidor"]);
        }
      });
     

    }
  }

  return (
    <>
    {success ? (
      <Box display={'flex'} justifyContent={'center'} my={'6vw'}>
        <div className="">
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              Request submitted successfully! Redirecting to login...   
          </Alert>          
          <LinearDeterminate />
          <Stack spacing={1} width={'100%'} justifyContent={'center'}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={'100%'} height={60} />
            <Skeleton variant="rounded" width={'100%'} height={60} />
          </Stack>
        </div>
      </Box>
    ) : (
      <Card variant="outlined">
      <MafirolIcon />
      <Typography
        component="h4"
        variant="body2"
        sx={{ width: '100%', fontSize: '16px', textAlign: 'center' }}
      >
        Welcome, request access to the customer area!
      </Typography>
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Request Access
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="fullName">Full name</FormLabel>
          <TextField
            autoComplete="fullName"
            name="fullName"
            required
            fullWidth
            id="fullName"
            onChange={validateInputs}
            placeholder="Jon Snow"
            error={nameError}
            helperText={nameErrorMessage}
            color={nameError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="companyName">Company name</FormLabel>
          <TextField
            autoComplete="companyName"
            name="companyName"
            required
            fullWidth
            id="companyName"
            placeholder="Ex: Mafirol"
            onChange={validateInputs}
            error={companyNameError}
            helperText={companyNameMessage}
            color={companyNameError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            required
            fullWidth
            id="email"
            placeholder="your@email.com"
            name="email"
            autoComplete="email"
            variant="outlined"
            onChange={validateInputs}
            error={emailError}
            helperText={emailErrorMessage}
            color={passwordError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            required
            fullWidth
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="new-password"
            variant="outlined"
            onChange={validateInputs}
            error={passwordError}
            helperText={passwordErrorMessage}
            color={passwordError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
          <TextField
            required
            fullWidth
            name="confirmPassword"
            placeholder="••••••"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            variant="outlined"
            onChange={validateInputs}
            error={confirmPasswordError}
            helperText={confirmPasswordErrorMessage}
            color={confirmPasswordError ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl sx={{mb:2 }}>
          <FormLabel htmlFor="customerCode">Customer Code</FormLabel>
          <TextField
            autoComplete="customerCode"
            name="customerCode"
            required
            fullWidth
            id="customerCode"
            type='tel'
            value={customerCode}
            onChange={(e) => {
              const onlyNumbers = e.target.value.replace(/\D/g, '');
              setCustomerCode(onlyNumbers);
              validateInputs();
            }}
            placeholder="Customer Code"
            error={customerCodeError}
            helperText={customerCodeErrorMessage}
            color={customerCodeError ? 'error' : 'primary'}
          />
        </FormControl>
        <Typography sx={{ textAlign: 'center' }}>
          Already have an account?{' '}
          <span>              
            <Link to={'signin'} 
              className={style.Link}              
            >
              Sign in
            </Link>
          </span>
        </Typography>
        {validateInputsResult ? (<Button
          type="submit"
          fullWidth
          disabled
          variant="contained"
          onClick={validateInputs}
        >
          Request Access
        </Button>) : (
          <Button
          type="submit"
          fullWidth        
          variant="contained"
          onClick={validateInputs}
        >
          Request Access
        </Button>)
        }
        
      </Box>   
      {serverErrors.length > 0 && (
        <div style={{ color: "red", marginTop: "10px" }}>
            <ul>
                {serverErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>
        </div>
      )}     
    </Card>
    )}

    <OpenResponseAccessModalDialog open={success}  counter={countdown}/>
    </>
  );
}

