import { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { KeyRound, Eye, EyeOff } from 'lucide-react';
import axios from 'axios';
import styles from './ResetPassword.module.css';
import { ResetPasswordFormData, ResetPasswordParams } from '../../services/types/reset-password';
import queryString from "query-string";
import FeedbackDialog from '../../components/FeedbackDialog/FeedbackDialog';
import AuthService from '../../services/api/Auth';

/*
    Ao chegar nesta página verificar no frontend se o token está na validade caso seja jwt,
    caso seja aspnet, enviar o token para validação, se estiver válido aparece o input, se não estiver,
    verificar também antes se aquele email é válido, caso não, exibir 
    uma mensagem dizendo que o link de recuperação de senha expirou, e dizer para tentar novamente, ou a conta é inválida
    se válido mandar feedback com disparo de email para o cliente, e mostrar no dialog, caso não só mostrar no dialog
*/

const passwordSchema = z.object({
  newPassword: z.string()
    .min(6, 'The password must be at least 6 characters long')
    .regex(/[A-Z]/, 'The password should contain at least 1 uppercase character')
    .regex(/[a-z]/, 'The password must contain at least one lowercase letter')
    .regex(/[0-9]/, 'The password must contain at least one number')
    .regex(/[^A-Za-z0-9]/, 'The password must contain at least one special character'),
  confirmationNewPassword: z.string()
}).refine((data) => data.newPassword === data.confirmationNewPassword, {
  message: "Passwords do not match",
  path: ["confirmationNewPassword"],
});

export default function ResetPassword({ email, token }: { email : string, token : string} ) {  
  const history = useHistory();  
  const [formData, setFormData] = useState<ResetPasswordFormData>({
    email: '',
    token: '',
    newPassword: '',
    confirmationNewPassword: '',    
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [availableButton, setAvailableButton] = useState(false);
  const [feedbackState, setFeedbackState] = useState({
    success: false,
    message: '',
  });

  useEffect(() => {
    const validateForm = () => {
      try {      
        passwordSchema.parse(formData);
        setErrors({});      
        setAvailableButton(true); 
        return true;
      } catch (error) {
        setAvailableButton(false); 
        if (error instanceof z.ZodError) {
          const newErrors: Record<string, string> = {};
          error.errors.forEach((err) => {
            const path = err.path[0] as string;
            newErrors[path] = err.message;
          });
          setErrors(newErrors);
        }      
        return false;
      }
    };

    validateForm();
  },[formData])

 

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!availableButton) return;    
    setAvailableButton(false);
        

    try {
      const formDataResetPassword : ResetPasswordFormData = {
        email: email,
        token: token,
        newPassword : formData.newPassword,
        confirmationNewPassword: formData.confirmationNewPassword
      }

        await AuthService.resetPassword(formDataResetPassword).then((res)=> {
          setFeedbackState({
            success: true,
            message: 'Your password has been successfully reset! You can now log in with your new password.',
          });
        })
        .catch(() => {
          setFeedbackState({
            success: false,
            message: 'We were unable to reset your password. Please try again or request a new reset link.',
          });          
        })
        .finally(() => {
          setDialogOpen(true);
          setAvailableButton(true); 
        });

    } catch (error) {

      setFeedbackState({
        success: false,
        message: 'We were unable to reset your password. Please try again or request a new reset link.',
      });
      setDialogOpen(true);
    }
  };

  return (
    <div className={styles.resetPasswordContainer}>
    <div className={styles.resetPasswordHeader}>
        <div className={styles.iconWrapper}>
            <KeyRound className={styles.icon} />
        </div>
        <h2 className={styles.title}>Reset Your Password</h2>
        <p className={styles.subtitle}>Enter your new password below</p>
    </div>

    <div className={styles.formContainer}>
        <div className={styles.formBox}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label htmlFor="Email" className={styles.formLabel}>
                        Email
                    </label>
                    <div className={styles.inputWrapper}>
                        <input
                            id="Email"
                            name="Email"
                            type={"text"}
                            value={email}
                            disabled
                            className={styles.formInput}
                            style={{ backgroundColor: '#cececee3'}}
                        />                       
                    </div>
                    {errors.password && <p className={styles.errorMessage}>{errors.password}</p>}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="password" className={styles.formLabel}>
                        New Password
                    </label>
                    <div className={styles.inputWrapper}>
                        <input
                            id="password"
                            name="password"
                            autoFocus
                            type={showPassword ? "text" : "password"}
                            value={formData.newPassword}
                            onChange={(e) =>
                                setFormData({ ...formData, newPassword: e.target.value })
                            }
                            className={styles.formInput}
                        />
                        <button
                            type="button"
                            className={styles.togglePassword}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <EyeOff /> : <Eye />}
                        </button>
                    </div>
                    {errors.newPassword && <p className={styles.errorMessage}>{errors.newPassword}</p>}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="confirmPassword" className={styles.formLabel}>
                        Confirm Your Password
                    </label>
                    <div className={styles.inputWrapper}>
                        <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            value={formData.confirmationNewPassword}
                            onChange={(e) =>
                                setFormData({ ...formData, confirmationNewPassword: e.target.value })
                            }
                            className={styles.formInput}
                        />
                        <button
                            type="button"
                            className={styles.togglePassword}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <EyeOff /> : <Eye />}
                        </button>
                    </div>
                    {errors.confirmationNewPassword && (
                        <p className={styles.errorMessage}>{errors.confirmationNewPassword}</p>
                    )}
                </div>
                {
                  availableButton  === true ? (
                  <button 
                    type="submit" 
                    className={styles.submitButton}                  
                    >
                      Reset Your Password
                  </button>

                  ) : (
                  <button 
                    type="reset" 
                    className={availableButton ? styles.submitButton : styles.submitButtonDisabled}                  
                    disabled={availableButton}
                    >
                      Reset Your Password
                  </button>
                   )
                } 
            </form>
        </div>
    </div>
    <FeedbackDialog
        open={dialogOpen}
        handleClose={() => {
          setDialogOpen(false);       
        }}
        success={feedbackState.success}
        message={feedbackState.message}
      />
</div>
  );
}